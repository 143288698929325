.TicTacToe {
  text-align: center;
}

.board {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  margin: 0 auto;
}

.cell {
  width: 100px;
  height: 100px;
  border: 1px solid #333;
  font-size: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cell:hover {
  background-color: #eee;
}

.X {
  color: #f00;
}

.O {
  color: #00f;
}

.winner {
  color: green;
}

.draw {
  color: gray;
}
.app {
  text-align: center;
}

.board {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  margin: 0 auto;
}

.cell {
  width: 100px;
  height: 100px;
  border: 1px solid #333;
  font-size: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cell:hover {
  background-color: #dad4d4;
}

.X {
  color: #f00;
}

.O {
  color: #00f;
}

.winner {
  color: green;
}

.draw {
  color: gray;
}
